import { CheckCircleIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"
import React, { useMemo } from "react"
import PrimaryButtonLink from "../components/buttons/PrimaryButtonLink"
import SecondaryButtonLink from "../components/buttons/SecondaryButtonLink"
import Layout from "../components/root/Layout"
import { useSocket } from "../context/socket-context"

const AuditStep = ({ text, complete }) => {
  return (
    <div className="flex items-center space-x-4">
      {complete ? (
        <div className="bg-yellow-400 text-gray-800 h-10 w-10 rounded-lg">
          <CheckCircleIcon />
        </div>
      ) : (
        <svg
          class="animate-spin h-10 w-10 text-yellow-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}

      <p className="text-xl font-medium">{text}</p>
    </div>
  )
}

const NewSite = () => {
  const { submitAudit, audits } = useSocket()
  const [submitted, setSubmitted] = React.useState(false)
  const [form, setForm] = React.useState({})

  const handleSubmit = e => {
    e.preventDefault()
    submitAudit(form.url, form.name, form.public)
    setSubmitted(true)
  }
  const onChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value })
  }

  const audit = useMemo(() => {
    if (!submitted) {
      return false
    }
    const auditKey = Object.keys(audits).find(key => {
      return audits[key].url === form.url
    })
    return audits[auditKey]
  }, [submitted, form, audits])

  if (submitted) {
    return (
      <Layout>
        {audit && (
          <div className="grid gap-4 py-8 max-w-md mx-auto">
            <p className="text-3xl">
              Evaluating{" "}
              <span className="text-yellow-500 font-bold">{audit.url}</span>
            </p>
            <AuditStep
              text={
                audit.ipInfo
                  ? "Gathered IP and CDN information"
                  : "Investigating site IP and CDN information"
              }
              complete={audit.ipInfo}
            />
            <AuditStep
              text={
                audit.greenData
                  ? "Gathered hosting information"
                  : "Investigating site's hosting'"
              }
              complete={audit.greenData}
            />
            <AuditStep
              text={
                audit.lighthouse
                  ? "Received performance report"
                  : "Evaluating site performance"
              }
              complete={audit.lighthouse}
            />
            <div className="grid grid-cols-2 gap-4 text-lg font-medium">
              <PrimaryButtonLink
                to={
                  form.public
                    ? `/story/${audit.uid}`
                    : `/private-story/${audit.uid}`
                }
              >
                View Story
              </PrimaryButtonLink>
              <PrimaryButtonLink to={`/report/${audit.uid}`}>
                View Report
              </PrimaryButtonLink>

              <div className="col-span-2">
                <SecondaryButtonLink to={`/dashboard`}>
                  Return to Dashboard
                </SecondaryButtonLink>
              </div>
            </div>
          </div>
        )}
      </Layout>
    )
  }

  return (
    <Layout title="New Site">
      <form
        className="space-y-8 divide-y divide-gray-700"
        onSubmit={handleSubmit}
      >
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Profile
            </h3>
            <p className="mt-1 max-w-2xl text-sm ">
              This information will be displayed publicly so be careful what you
              share.
            </p>
          </div>

          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-700 sm:pt-5">
              <label
                htmlFor="url"
                className="block text-sm font-medium  sm:mt-px sm:pt-2"
              >
                URL
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-800 bg-gray-100 dark:bg-gray-800 sm:text-sm">
                    https://
                  </span>
                  <input
                    type="text"
                    name="url"
                    id="url"
                    value={form.url}
                    onChange={onChange}
                    autoComplete="web-address"
                    className="flex-1 block w-full rounded-none rounded-r-md bg-primary px-3 py-2 border-gray-700 border-2 shadow-sm placeholder-gray-600 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>

            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-700 sm:pt-5">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium  sm:mt-px sm:pt-2"
                >
                  Identifier
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="input"
                    value={form.name}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-700 sm:pt-5">
                <label className="block text-sm font-medium  sm:mt-px sm:pt-2">
                  Other Info
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="public"
                        name="public"
                        type="checkbox"
                        className="checkbox"
                        onChange={e =>
                          setForm({ ...form, public: e.target.checked })
                        }
                        checked={form.public}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="public" className="font-bold ">
                        Add to Public Database
                      </label>
                      <p className="">
                        Allow others to explore these findings. We will also
                        include your URL in our aggregate insights.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end space-x-2">
            <SecondaryButtonLink to="/dashboard">Cancel</SecondaryButtonLink>
            <button
              type="submit"
              className="flex items-center justify-center px-4 py-2 font-bold rounded-md shadow-sm text-gray-900 bg-yellow-500 sm:px-8"
            >
              Add
            </button>
          </div>
        </div>
      </form>
    </Layout>
  )
}
export default NewSite
