import * as React from "react"

import { Link } from "gatsby"

const SecondaryButtonLink = ({ children, to }) => {
  return (
    <Link
      to={to}
      className="flex items-center justify-center px-4 py-2 font-bold rounded-md shadow-md text-gray-900 bg-white hover:bg-gray-100 sm:px-8"
    >
      {children}
    </Link>
  )
}

export default SecondaryButtonLink
